<template>
  <div>
    <iframe width="100%" height="1000px"
      src="https://lookerstudio.google.com/embed/reporting/c5504121-b219-4898-8419-039e45994e93/page/QzuED"
      frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
}
</script>
